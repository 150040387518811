require('./src/styles/main.scss');

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
exports.shouldUpdateScroll = ({prevRouterProps}) => {
    const comingFromInternalPage = prevRouterProps?.location.origin === window.location.origin;

    if (!comingFromInternalPage) return;

    const siteNavbar = document.querySelector('#content');

    if (!siteNavbar) return;

    window.scrollTo({top: siteNavbar.offsetTop, left: 0});
}
