// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-india-agenda-tsx": () => import("./../../../src/pages/india/agenda.tsx" /* webpackChunkName: "component---src-pages-india-agenda-tsx" */),
  "component---src-pages-india-index-tsx": () => import("./../../../src/pages/india/index.tsx" /* webpackChunkName: "component---src-pages-india-index-tsx" */),
  "component---src-pages-india-proceedings-tsx": () => import("./../../../src/pages/india/proceedings.tsx" /* webpackChunkName: "component---src-pages-india-proceedings-tsx" */),
  "component---src-pages-india-speakers-tsx": () => import("./../../../src/pages/india/speakers.tsx" /* webpackChunkName: "component---src-pages-india-speakers-tsx" */),
  "component---src-pages-north-america-agenda-tsx": () => import("./../../../src/pages/north-america/agenda.tsx" /* webpackChunkName: "component---src-pages-north-america-agenda-tsx" */),
  "component---src-pages-north-america-index-tsx": () => import("./../../../src/pages/north-america/index.tsx" /* webpackChunkName: "component---src-pages-north-america-index-tsx" */),
  "component---src-pages-north-america-proceedings-tsx": () => import("./../../../src/pages/north-america/proceedings.tsx" /* webpackChunkName: "component---src-pages-north-america-proceedings-tsx" */),
  "component---src-pages-north-america-speakers-tsx": () => import("./../../../src/pages/north-america/speakers.tsx" /* webpackChunkName: "component---src-pages-north-america-speakers-tsx" */),
  "component---src-templates-speaker-details-page-tsx": () => import("./../../../src/templates/SpeakerDetailsPage.tsx" /* webpackChunkName: "component---src-templates-speaker-details-page-tsx" */)
}

